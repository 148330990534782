import Container from 'react-bootstrap/Container';
import { Navbar } from 'react-bootstrap';
import React from 'react';

const Header = () => {
 
    return (
        <>
            <Navbar bg="secondary" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand href="/" style={{ color: 'white' }}>Bestellliste</Navbar.Brand>
                    <Navbar.Toggle />
                </Container>
            </Navbar>
        </>
    );
}

export default Header;