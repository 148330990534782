import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Dropdownapi } from '../Utils/tools';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';

function Orderproductlist(providerId) {

    const [lastname, setLastname] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProductOfferTypeId, setSelectedProductOfferTypeId] = useState(null);
    const [selectedCustomerTypeId, setSelectedCustomerTypeId] = useState(null);
    const [selectedshippingAddressCountryId, setselectedshippingAddressCountryId] = useState(null);
    const [selectedorderProductStatusId, setSelectedorderProductStatusId] = useState(null);
    const [selectedsendAdvertisingMail, setSelectedsendAdvertisingMail] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const localeText = AG_GRID_LOCALE_DE;
    const lKZ = [
        { value: '1', label: 'Deutschland DE' },
        { value: '2', label: 'Schweiz CH' },
        { value: '3', label: 'Österreich AT' },
        { value: '', label: 'Alle' },
    ];
    const magazin = [
        { value: 'true', label: 'Opt_in' },
        { value: 'false', label: 'Opt_out' },
        { value: '', label: 'Alle' },
    ];

    const status = [
        { value: 0, label: 'Aktiv' },
        { value: 1, label: 'Inaktiv' },
        { value: '-1', label: 'Alle' },
    ];
    const handleSelect = (value, key) => {
        switch (key) {
            case 'productId':
                setSelectedProductId(value);
                setPageNumber(1);
                break;
            case 'productOfferTypeId':
                setSelectedProductOfferTypeId(value);
                setPageNumber(1);
                break;
            case 'customerTypeId':
                setSelectedCustomerTypeId(value);
                setPageNumber(1);
                break;
            case 'shippingAddressCountryId':
                setselectedshippingAddressCountryId(value);
                setPageNumber(1);
                break;
            case 'orderProductStatusId':
                setSelectedorderProductStatusId(value);
                setPageNumber(1);
                break;
            case 'sendAdvertisingMail':
                setSelectedsendAdvertisingMail(value);
                setPageNumber(1);
                break;
            default:
                break;
        }
    };

    const handleChange = (event) => {
        setLastname(event.target.value);
        setPageNumber(1);
    };
    const [list, setList] = useState(null);
    const [colDefs] = useState([
        { field: "ID", filter: true },
        { field: "Datum", filter: true },
        { field: "Zeitschrift", filter: true },
        { field: "Angebotsart", filter: true },
        { field: "Logistikpartner", filter: true },
        { field: "Lieferbeginn", filter: true },
        { field: "Folge-RG", filter: true },
        { field: "Soll", filter: true },
        { field: "Ist", filter: true },
        { field: "Lieferende", filter: true },
        { field: "R-Anrede", filter: true },
        { field: "R-Vorname", filter: true },
        { field: "R-Nachname", filter: true },
        { field: "R-Firma", filter: true },
        { field: "R-Strasse", filter: true },
        { field: "R-PLZ", filter: true },
        { field: "R-Stadt", filter: true },
        { field: "R-LKZ", filter: true },
        { field: "Kunde", filter: true },
        { field: "K-E-Mail", filter: true },
        { field: "Abopreis", filter: true },
        { field: "Währung-Code", filter: true },
        { field: "Währung-Symbol", filter: true },
        { field: "Zahlungsart", filter: true },
        { field: "Zahlungsrhythmus", filter: true },
        { field: "Abodauer ", filter: true },
        { field: "L-Anrede", filter: true },
        { field: "L-Vorname", filter: true },
        { field: "L-Nachname ", filter: true },
        { field: "L-Firma", filter: true },
        { field: "L-Strasse", filter: true },
        { field: "L-PLZ", filter: true },
        { field: "L-Ort", filter: true },
        { field: "L-LKZ", filter: true },
        { field: "L=R", filter: true },
        { field: "Newsletter", filter: true }

    ]);

    useEffect(() => {
        if (providerId.providerId !== null) {
            axios.get('https://prp20-wa-orderlist-api01.azurewebsites.net/api/OrderProductList/OrderProductListSelectTable', {
                params: {
                    productId: selectedProductId?.value,
                    customerTypeId: selectedCustomerTypeId?.value,
                    productOfferTypeId: selectedProductOfferTypeId?.value,
                    shippingAddressCountryId: selectedshippingAddressCountryId?.value,
                    orderProductStatusId: selectedorderProductStatusId?.value,
                    sendAdvertisingMail: selectedsendAdvertisingMail?.value,
                    shippingAddressLastname: lastname,
                    providerId: providerId.providerId,
                    pageNumber: pageNumber
                }


            })
                .then(response => {
                    const mappedData = response.data.map(item => ({
                        ID: item.orderProductId,
                        Datum: item.orderProductDt,
                        Zeitschrift: item.productName,
                        Angebotsart: item.productOfferType,
                        Logistikpartner: item.logisticPartner,
                        Lieferbeginn: item.shippingStart,
                        "Folge-RG": item.subsequentBillingNo,
                        Soll: item.statusUserStatus,
                        Ist: item.statusProviderStatus,
                        Lieferende: item.shippingEnd,
                        "R-Anrede": item.billingAddressTitle,
                        "R-Vorname": item.billingAddressFirstname,
                        "R-Nachname": item.billingAddressLastname,
                        "R-Firma": item.billingAddressCompany,
                        "R-Strasse": item.billingAddressStreet,
                        "R-PLZ": item.billingAddressZip,
                        "R-Stadt": item.billingAddressCity,
                        "R-LKZ": item.billingAddressCountryCode,
                        Kunde: item.customerId,
                        "K-E-Mail": item.customerEmail,
                        Abopreis: item.price,
                        "Währung-Code": item.currencyCode,
                        "Währung-Symbol": item.currencySymbol,
                        Zahlungsart: item.paymentType,
                        Zahlungsrhythmus: item.paymenterval,
                        "Abodauer ": item.orderProductSubscriptionDuration,
                        "L-Anrede": item.shippingAddressTitle,
                        "L-Vorname": item.shippingAddressFirstname,
                        "L-Nachname ": item.shippingAddressLastname,
                        "L-Firma": item.shippingAddressCompany,
                        "L-Strasse": item.shippingAddressStreet,
                        "L-PLZ": item.shippingAddressZip,
                        "L-Ort": item.shippingAddressCity,
                        "L-LKZ": item.shippingAddressCountryCode,
                        "L=R": item.shippingIsBillingAddress,
                        Newsletter: item.sendAdvertisingMail
                    }));
                    setList(mappedData);
                })
                .catch(error => {
                    console.error('There was a problem with the request:', error);
                });

        }
    }, [selectedProductId, selectedCustomerTypeId, selectedProductOfferTypeId, selectedshippingAddressCountryId, selectedorderProductStatusId, selectedsendAdvertisingMail, lastname, pageNumber, providerId]);


    const incrementPageNumber = () => {
        setPageNumber(prevPageNumber => prevPageNumber + 1);
    };

    const decrementPageNumber = () => {
        setPageNumber(prevPageNumber => prevPageNumber - 1);
    };



    return (
        <div className="admin_container">
            <div>

                <Container fluid className="dropdowncontainer">
                    <Row >
                        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                            <h4>Zeitschrift</h4>
                            <Dropdownapi apiUrl='https://prp20-wa-orderlist-api01.azurewebsites.net/api/Filter/GetProductList?userId=20620133' labelKey='productName' valueKey='productId' onSelectedItemChange={handleSelect} />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                            <h4>Angebot</h4>
                            <Dropdownapi apiUrl='https://prp20-wa-orderlist-api01.azurewebsites.net/api/Filter/GetProductOffer' labelKey='namePublic' valueKey='productOfferTypeId' onSelectedItemChange={handleSelect} />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                            <h4>Kundentyp</h4>
                            <Dropdownapi apiUrl='https://prp20-wa-orderlist-api01.azurewebsites.net/api/Filter/GetCustomerType' labelKey='type' valueKey='customerTypeId' onSelectedItemChange={handleSelect} />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                            <h4>L-LKZ</h4>
                            <Dropdown options={lKZ} onSelectedItemChange={handleSelect} valueKey='shippingAddressCountryId' />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={1}>
                            <h4>Status</h4>
                            <Dropdown options={status} onSelectedItemChange={handleSelect} valueKey='orderProductStatusId' />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={1}>
                            <h4>Newsletter</h4>
                            <Dropdown options={magazin} onSelectedItemChange={handleSelect} valueKey='sendAdvertisingMail' />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={1}>
                            <h4>LNachname</h4>
                            <input className="inputfilter"
                                type="text"
                                defaultValue={lastname}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        handleChange(event);
                                    }
                                }}
                            />


                        </Col>



                    </Row>
                </Container>
            </div>
            <div
                className="ag-theme-quartz"
                style={{ height: 700 }}
            >
                <AgGridReact
                    rowData={list}
                    columnDefs={colDefs}
                    localeText={localeText}
                />
            </div>
            <div className="paging">
                <button className="page-btn" onClick={decrementPageNumber}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <div> Seite: {pageNumber} </div>
                <button className="page-btn" onClick={incrementPageNumber}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
        </div>
    );
}

export default Orderproductlist;

