import React, { useState, useEffect } from 'react';
import logo from '../../Resources/images/PPlusLogo.png';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from 'axios';


const Dropdownapi = ({ onSelectedItemChange, apiUrl, labelKey, valueKey }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                const formattedOptions = formatOptions(response.data, labelKey, valueKey);
                setOptions(formattedOptions);
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
            }
        };

        fetchData();
    }, [apiUrl, labelKey, valueKey]);

    const formatOptions = (data, labelKey, valueKey) => {
        return data.map(item => ({
            label: item[labelKey],
            value: item[valueKey]
        }));
    };

    const onSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        onSelectedItemChange(selectedOption, valueKey);
    };

    return (
        <div>
            <Select
                value={selectedOption}
                options={options}
                onChange={onSelectChange}
                isSearchable={true}
                isClearable={true}
                placeholder="" 
            />
        </div>
    );
};




const Dropdown = ({ options, onSelectedItemChange, valueKey }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);

    const handleSelect = (option) => {
        setSelectedOption(option);
        setSearchTerm('');
        onSelectedItemChange(option, valueKey);
    };

    const filteredOptions = options.filter((option) =>
        option.label && option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <Select
                value={selectedOption}
                options={filteredOptions}
                onChange={handleSelect}
                isSearchable={true}
                isClearable={true}
                placeholder="" 
            />
        </div>
    );
};


export const showErrorToast = (msg) => {
    toast.error(msg, {
        position: toast.POSITION.TOP_LEFT
    })
}

export const showSuccessToast = (msg) => {
    toast.success(msg, {
        position: toast.POSITION.TOP_LEFT
    })
}
const Logo = (props) => {
    const template = <div
        className="img_cover"
        style={{
            width: props.width,
            height: props.height,
            background: `url(${logo}) no-repeat`
        }}
    ></div>


    return template


}
export { Dropdown, Dropdownapi, Logo };
