import Card from 'react-bootstrap/Card';
import { Logo } from '../Utils/tools'

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <Card>
            <Card.Footer className="footer">
            <div className="footer_logo">
                <Logo
                    link={true}
                    linkTo={'/'}
                    width="150px"
                    height="70px"
                />
            </div>
                <div className="footer_descl">
                    {currentYear} - Presseplus
                </div>
            </Card.Footer>
        </Card>
    );
}

export default Footer;


