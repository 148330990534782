import React from 'react';
import { createRoot } from "react-dom/client";
import './Resources/css/app.css'
import Routepage from './routes';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <Routepage />
    </React.StrictMode>,
);