import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header_footer/header'
import Footer from './components/Header_footer/footer'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import Orderproductlist from './components/Home/orderproductlist'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Routepage = () => {
    const [providerId, setProviderId] = useState(null);
    const [isValidToken, setIsValidToken] = useState(true);

    const fetchData = () => {
        const token = window.location.pathname.slice(1);
        if (token !== "") {
            axios.get(`https://prp20-wa-orderlist-api01.azurewebsites.net/api/Token/GetProvider?accessToken=${token}`)
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data;
                        const isValid = data.every(item => item.statusId !== 2);
                        const mappedData = response.data.map(item => ({
                            providerId: item.providerId
                        }));
                        setProviderId(mappedData[0].providerId);
                        setIsValidToken(isValid);

                    } else {
                        //    throw new Error('Fehler beim Abrufen der Daten');
                    }
                })
                .catch(error => {
                    console.error('Fehler:', error);
                    setIsValidToken(false);
                })
                .finally(() => {
                });
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 3 * 60 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);



    if (!isValidToken) {

        return <div> <Header /><div className="wrapper"><h2>Token ist abgelaufen</h2></div> <Footer /></div>;
    }


    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route
                    path="/:token"
                    element={
                        isValidToken ? (
                            <Orderproductlist providerId={providerId}
                            />
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route path="/" element={<div className="wrapper"><h2>Zugriff verweigert</h2></div>} />
            </Routes>
            <ToastContainer />
            <Footer />
        </BrowserRouter>
    );
}
export default Routepage;